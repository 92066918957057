<template>
  <div>
    <section class="margin-bottom-100">
      <v-row class="margin-bottom-100 md-reverse">
        <v-col cols="12" lg="5" order-xs="12" order-lg="1">
          <h1 class="text-h4 font-weight-bold mb-6">What Is a Reverse Mortgage?</h1>
          <p class="text-h6 font-weight-regular mb-6">
            The most common type of reverse mortgage is a loan insured by the Federal Housing
            Administration (FHA), which is also called a HECM. It allows you to access your home
            equity and turn it into cash.
          </p>
          <p class="text-h6 font-weight-regular mb-16">
            Borrowers choose a reverse mortgage because it allows them to remain in their homes, as
            long as they meet the loan terms, and provides funds that can greatly supplement their
            retirement income.
          </p>

          <v-btn
            x-large
            color="success"
            class="text-none text-h6 font-weight-medium rounded px-10"
            :to="{ name: 'Form' }"
          >
            Get Started
          </v-btn>
        </v-col>
        <v-spacer class="d-none-xs d-block-md order-sm-2"></v-spacer>
        <v-col cols="12" lg="5" order-xs="1" order-lg="12">
          <v-img src="~@/assets/about-page/main.png"></v-img>
        </v-col>
      </v-row>
    </section>

    <section class="margin-bottom-100">
      <h3 class="text-h4 font-weight-bold text-center margin-bottom-100">
        How Can A Reverse Mortgage Help You?
      </h3>
      <v-sheet
        :width="$vuetify.breakpoint.name === 'lg' ? '100%' : '80%'"
        class="mx-auto d-flex flex-wrap align-start justify-space-between transparent"
      >
        <div class="help-item-container">
          <div class="help-icon-container d-flex align-center justify-center mb-8">
            <img class="help-icon" src="~@/assets/about-page/help-icons/supplement-icon.png" />
          </div>
          <div class="text-center text-h6 font-weight-regular">
            Supplement your retirement income
          </div>
        </div>
        <div class="help-item-container">
          <div class="help-icon-container d-flex align-center justify-center mb-8">
            <img class="help-icon" src="~@/assets/about-page/help-icons/payoff-icon.png" />
          </div>
          <div class="text-center text-h6 font-weight-regular">Pay off your existing mortgage</div>
        </div>
        <div class="help-item-container">
          <div class="help-icon-container d-flex align-center justify-center mb-8">
            <img class="help-icon" src="~@/assets/about-page/help-icons/structure-icon.png" />
          </div>
          <div class="text-center text-h6 font-weight-regular">
            Structure the timing of your access to funds
          </div>
        </div>
        <div class="help-item-container">
          <div class="help-icon-container d-flex align-center justify-center mb-8">
            <img class="help-icon" src="~@/assets/about-page/help-icons/retirement-icon.png" />
          </div>
          <div class="text-center text-h6 font-weight-regular">Stretch retirement savings</div>
        </div>
        <div class="help-item-container">
          <div class="help-icon-container d-flex align-center justify-center mb-8">
            <img class="help-icon" src="~@/assets/about-page/help-icons/home-icon.png" />
          </div>
          <div class="text-center text-h6 font-weight-regular">Stay in your home</div>
        </div>
      </v-sheet>
    </section>

    <section class="margin-bottom-100">
      <h3 class="text-h4 font-weight-bold text-center mb-16">Frequently asked questions</h3>

      <div>
        <v-expansion-panels focusable>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-h6">
              Does the bank own my home?
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-h6 font-weight-regular">
              <div class="pt-6 pb-3">
                No. The home belongs to you. Whomever you name in your Will or Trust will inherit
                the home.
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="text-h6">
              How much money can I qualify for?
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-h6 font-weight-regular">
              <div class="pt-6 pb-3">
                The amount for which you qualify depends on your age, the appraised value and
                interest rates.
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="text-h6">
              How do I receive my funds?
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-h6 font-weight-regular">
              <div class="pt-6 pb-3">
                It's possible to receive funds immediately when the loan is funded or receive future
                direct deposits into your bank account every month, or draw from a line of credit in
                the future. You can combine all three options and you can initiate changes in the
                future with an easy phone call.
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="text-h6">
              How is the rate of growth on the credit line calculated?
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-h6 font-weight-regular">
              <div class="pt-6 pb-3">
                It's the same as the accrual rate on the loan balance: the 1-year US Treasury Bill
                rate (set at the beginning of every month), plus a margin, plus 0.5% (FHA's mortgage
                insurance premium). The average line of credit growth rate over the last 32 years
                has been approximately 5.1%. Over the last 30 years, the average annual inflation
                rate has been 2.7%, according to
                <a href="https://www.usinflationcalculator.com" target="_blank"
                  >https://www.usinflationcalculator.com</a
                >
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </section>

    <section class="mb-16">
      <h4 class="text-h4 font-weight-bold mb-8">Still have questions?</h4>
      <v-row class="align-baseline">
        <v-col cols="12" md="6" class="text-h4 font-weight-bold"
          >Contact us and our consultants will help you!</v-col
        >
        <v-col cols="12" md="6">
          <div class="d-flex justify-end">
            <div class="mr-16">
              <a
                class="text-decoration-none text-h5 black--text"
                :href="`tel:${loanOfficer.phone}`"
                >{{ loanOfficer.phone }}</a
              >
            </div>
            <div>
              <a
                class="text-decoration-none text-h5 black--text"
                :href="`mailto:${loanOfficer.email}`"
                >{{ loanOfficer.email }}</a
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
export default {
  computed: {
    loanOfficer() {
      return this.$store.state.loanOfficer.info;
    }
  }
};
</script>

<style scoped>
html {
  overflow-y: auto;
}
.margin-bottom-100 {
  margin-bottom: 100px;
}

.help-item-container {
  width: 100%;
  max-width: 220px;
  margin-bottom: 40px;
}

.help-icon-container {
  width: 114px;
  height: 114px;
  border-radius: 50%;
  background: #fbfcfe;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
  margin: 0 auto;
}

.help-icon {
  width: 50%;
  height: auto;
  object-fit: contain;
}
</style>
